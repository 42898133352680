import "./App.css";

import {useEffect} from "react";

function App() {
  useEffect(() => {
    // Redirect to the PDF
    window.location.href = "https://firebasestorage.googleapis.com/v0/b/dev-gem-app.appspot.com/o/be_there_iteration_Jeremy_03.pdf?alt=media";
  }, []);

  return (
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
        textAlign: "center"
      }}>
        <h1>Redirecting to PDF...</h1>
        <p>
          If the redirect doesn't happen automatically, please
          <a href="https://firebasestorage.googleapis.com/v0/b/dev-gem-app.appspot.com/o/be_there_iteration_Jeremy_03.pdf?alt=media"> click here</a>.
        </p>
      </div>
  );
}

export default App;
